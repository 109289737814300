<template>
  <img :src="mySVG" />
</template>

<script>
// import IconChangeDoctor from '../../../../public/assets/media/icons/change-user.svg' 
import IconChangeDoctor from "@/assets/icons/change-user.svg";
export default {
  name: 'ChangeIcon',
  data(){
    return {
      mySVG: IconChangeDoctor
    }
  }
}
</script>