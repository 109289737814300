<template>
    <v-breakpoint>
        <div slot-scope="scope">
            <div class="row border rounded p-3" id="ControlDoctorCaller">
                <div class="col-6" id="printMe">
                    <div class="row">
                        <div class="col-auto">
                            <h5 class="text-uppercase text-truncate">
                                {{
                                    dokter
                                        | spesialisasiName
                                }}
                            </h5>
                            <h6 class="text-truncate">
                                {{
                                    dokter
                                        ? dokter.nama
                                        : "Anda Belum Memilih Dokter"
                                }}
                            </h6>
                            <h6 class="text-truncate" v-if="jadwal">
                                {{ jadwal.jamMulai }} - {{ jadwal.jamSelesai }}
                            </h6>
                        </div>
                        <div class="col-auto">
                            <div
                                class="ml-2"
                                v-if="
                                    scope.isMedium ||
                                        scope.isSmall ||
                                        scope.noMatch
                                "
                            >
                                <button
                                    type="button"
                                    class="btn btn-default btn-elevate btn-circle btn-icon"
                                    @click="openModal"
                                >
                                    <!-- <i class="fa fa-edit kt-font-primary"></i> -->
                                    <ChangeDoctorIcon style="width: 60%;" />
                                </button>
                                <doctor-control ref="modal"></doctor-control>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-9 col-sm-9 col-auto">
                            <div class="d-flex">
                                <div class="mr-2">
                                    <h1
                                        v-if="selectedSlot"
                                        class="kt-font-boldest"
                                    >
                                        {{ selectedSlot.queueNoFormatted }}
                                    </h1>
                                    <h1 v-else class="kt-font-boldest">
                                        00000
                                    </h1>
                                </div>
                                <div class=" m-auto ">
                                    <p
                                        class="kt-font-sm kt-font-bold mt-1"
                                        v-if="
                                            selectedSlot &&
                                                selectedSlot.queueStatus ==
                                                    'SERVING'
                                        "
                                    >
                                        Dilayani <br />
                                        {{
                                            selectedSlot.lastModifiedDate
                                                | formatDate(nowTime)
                                        }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="selectedSlot"
                            class="col-md-3 col-sm-3 col-auto"
                        >
                            <button
                                v-if="!isLoading && selectedSlot"
                                class="btn btn-default text-uppercase w-100"
                                @click="checkPrint(2)"
                            >
                                <i class="fas fa-print"></i>
                            </button>
                            <button
                                v-else
                                class="btn btn-default text-uppercase w-100"
                                type="button"
                                disabled
                            >
                                <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            </button>
                        </div>
                    </div>
                    <textarea
                        class="form-control rounded"
                        v-if="selectedSlot"
                        v-model="selectedSlot.note"
                    ></textarea>
                </div>
                <div class="col-6">
                    <div class="row">
                        <div
                            class="col-12 col-sm-6 col-md-6 mt-2"
                            v-if="!selectedSlot || !isTeleconsult"
                        >
                            <button
                                class="btn btn-default text-uppercase w-100"
                                @click="verifySelectedSlot(selectedSlot, 1)"
                            >
                                <!-- <i class="la la-bullhorn"></i> -->
                                Panggil
                            </button>
                        </div>
                        <div
                            class="col-12 col-sm-6 col-md-6 mt-2"
                            v-if="!selectedSlot || !isTeleconsult"
                        >
                            <!-- slottype diganti dengan tipe telmed -->
                            <button
                                class="btn btn-default text-uppercase w-100"
                                @click="verifySelectedSlot(selectedSlot, 2)"
                            >
                                Layani
                            </button>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 mt-2" v-else>
                            <button
                                class="btn btn-default text-uppercase w-100"
                                @click="openTelmed()"
                            >
                                <a>
                                    <!-- <i class="fas fa-headset"></i> -->
                                    Join Telekonsul
                                </a>
                            </button>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 mt-2">
                            <button
                                class="btn btn-default text-uppercase w-100"
                                @click="verifySelectedSlot(selectedSlot, 3)"
                            >
                                Selesai
                            </button>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 mt-2">
                            <button
                                class="btn btn-default text-uppercase w-100"
                                @click="updateQueue(selectedSlot, 4)"
                            >
                                Lewati
                            </button>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 mt-2">
                            <button
                                v-if="!isLoading"
                                class="btn btn-default text-uppercase w-100"
                                @click="checkPrint(1)"
                                :disabled="
                                    listBookmarkDokter.length === 0
                                        ? true
                                        : false
                                "
                            >
                                Cetak Baru WalkIn
                            </button>
                            <button
                                v-else
                                class="btn btn-default text-uppercase w-100"
                                type="button"
                                disabled
                            >
                                <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                Loading...
                            </button>
                        </div>
                        <div
                            class="col-12 col-sm-6 col-md-6 mt-2"
                            v-if="!queueUnitPassword"
                        >
                            <button
                                class="btn btn-default text-uppercase w-100"
                                @click.prevent="showModal()"
                            >
                                Pindah
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-breakpoint>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import QueueStatus from "./../../../../constants/queue-resource-enum";
import PrintBilling from "../../../transaksi_referrals/TransaksiReferralPrint";
import { RepositoryFactory } from "./../../../../repositories/RepositoryFactory";
const TelekonsulRepository = RepositoryFactory.get("teleconsultResource");
import QueueDevice from "./../../../../model/queue-device-model";
import CallerVue from "../../Caller.vue";
import { VBreakpoint } from "vue-breakpoint-component";
import DoctorControl from "./DoctorControl.vue";
import ChangeDoctorIcon from "../../../_base/IconChangeDoctor";
import { JSEncrypt } from "jsencrypt";
import SweetAlertTemplate from "../../../_general/SweetAlert";
import BlockUI from "../../../_js/BlockUI";
import LoadSpinner from "./../../../_general/LoadSpinner.vue";
const blockUI = new BlockUI();
const SweetAlert = Vue.extend(SweetAlertTemplate);

export default {
    data() {
        return {
            countUp: null,
            permission: true,
            actionsEnum: ["PINDAH", "PANGGIL", "LAYANI", "SELESAI", "LEWATI"],
            nowTime: new Date()
        };
    },
    components: {
        DoctorControl,
        ChangeDoctorIcon,
        VBreakpoint
    },
    filters: {
        formatDate(date, now) {
            let Timenow = moment(now);
            let end = moment(date);
            let duration = moment.duration(Timenow.diff(end));

            let jam = duration.hours();
            let menit = duration.minutes();
            let detik = duration.seconds();

            jam < 10 ? (jam = "0" + jam) : jam;
            menit < 10 ? (menit = "0" + menit) : menit;
            detik < 10 ? (detik = "0" + detik) : detik;

            return `${jam}:${menit}:${detik}`;
        },
        spesialisasiName(val){
            let spesialisasiName = "Anda Belum Memilih Dokter"
            if (val) {
                if (val.dokterSpesialisasis) {
                    val.dokterSpesialisasis.forEach(spe => {
                        if (spe.default) {
                            if(spe.spesialisasi){
                                spesialisasiName = spe.spesialisasi.namaIndo
                            }
                        }
                    });
                }
            }
            return spesialisasiName
        }
    },
    computed: {
        ...mapState("DoctorCallerStore", {
            selectedDate: state => state.selectedDate,
            dokter: state => state.dokter,
            selectedSlot: state => state.selectedSlot,
            serverTime: state => state.serverTime,
            jadwal: state => state.jadwal,
            queueUnitPassword: state => state.queueUnitPassword,
            listBookmarkDokter: state => state.listBookmarkDokter
        }),
        ...mapState("CallerStore", {
            userNotification: state => state.userNotification,
            sessionId: state => state.sessionId,
            viewCaller: state => state.viewCaller,
            unitQueue: state => state.unitQueue,
            queueDevice: state => state.queueDevice,
            defaultPrinter: state => state.defaultPrinter,
            isLoading: state => state.isLoading
        }),
        ...mapState("DoctorCallerStore", {
            doctorCallerName: state => state.doctorCallerName
        }),
        schedule() {
            return `(${helpers.daysToId(this.selectedSlot.queueUnit.hari)}, ${
                this.selectedSlot.queueUnit.jamMulai
            } - ${this.selectedSlot.queueUnit.jamSelesai})`;
        },
        getContent() {
            return JSON.stringify({
                content: this.selectedSlot.encryptedId,
                type: "antrian"
            });
        },

        isTeleconsult() {
            if (
                this.selectedSlot.queue &&
                this.selectedSlot.queue.reservasi.teleconsultPayment
            ) {
                return true;
            }
        }
    },
    methods: {
        ...mapMutations({
            setSelectedUnit: "UnitCallerStore/SET_SELECTED_UNIT",
            setDoctorCallerName: "DoctorCallerStore/SET_DOCTOR_CALLER_NAME",
            setServerTime: "DoctorCallerStore/SET_SERVER_TIME",
            setShowModalPindah: "DoctorCallerStore/SET_SHOW_MODAL_PINDAH",
            setListPrinter: "CallerStore/SET_LIST_PRINTER",
            setDefaultPrinter: "CallerStore/SET_DEFAULT_PRINTER",
            setShowModalPrinter: "CallerStore/SET_SHOW_MODAL_PRINTER",
            setLoading: "CallerStore/SET_LOADING",
            setShowModalLinkTelekonsul:
                "DoctorCallerStore/SET_SHOW_LINK_TELEKONSUL",
            setLinkTelekonsul: "DoctorCallerStore/SET_LINK_TELEKONSUL",
            setUsePrinter: "CallerStore/SET_USE_PRINTER"
        }),
        ...mapActions({
            saveQueueDevice: "CallerStore/SAVE_QUEUE_DEVICE",
            getServerTime: "DoctorCallerStore/GET_SERVER_TIME",
            updateQueueStatus: "UnitCallerStore/UPDATE_QUEUE_STATUS",
            SOCK_MSG: "DoctorAppointmentStore/SOCK_MSG"
        }),

        pad(num) {
            return ("0" + num).slice(-2);
        },
        verifySelectedSlot(payload, num) {
            if (this.selectedSlot) {
                let payloadDate = payload.date ? payload.date : payload.tanggal;
                let inputDate = new Date(payloadDate);
                var todaysDate = new Date();
                if (
                    inputDate.setHours(0, 0, 0, 0) ==
                    todaysDate.setHours(0, 0, 0, 0)
                ) {
                    this.updateQueue(payload, num);
                    // Date equals today's date
                } else {
                    toastr.error("Tanggal Tidak Sesuai");
                }
            } else {
                this.$emit("clicked", "val");
                toastr.error("Belum Memilih Slot");
            }
        },
        async openTelmed() {
            let vx = this;
            if (
                vx.selectedSlot.queue.reservasi.teleconsultPayment.status ==
                "UNDERPAYMENT"
            ) {
                toastr.error("Belum melakukan pembayaran");
            } else {
                let pas = `${
                    vx.selectedSlot.queue.reservasi.teleconsultPayment.id
                }:${vx.selectedSlot.queue.reservasi.id}:${
                    vx.selectedSlot.queue.reservasi.patient.id
                }:${vx.dokter.id}`;
                let encryptPas = this.encryptData(pas);
                // let tenant = JSON.parse(localStorage.getItem("selectedTent"));

                // let link = {
                //     linkId: vx.selectedSlot.queue.reservasi.teleconsultPayment.linkId,
                //     password: vx.selectedSlot.queue.reservasi.teleconsultPayment.password,
                //     key: encryptPas,
                //     queueDeviceUniqueId: vx.queueDevice.uniqueId,
                //     tenantId: tenant.id
                // }
                let link = await TelekonsulRepository.generateLink();
                var baseUrl = link.data.split("?");
                let obj = Object.fromEntries(new URLSearchParams(baseUrl[1]));
                obj.linkId =
                    vx.selectedSlot.queue.reservasi.teleconsultPayment.linkId;
                obj.password =
                    vx.selectedSlot.queue.reservasi.teleconsultPayment.password;
                obj.key = encryptPas;
                obj.queueDeviceUniqueId = vx.queueDevice.uniqueId;
                obj.tenantId = window.tenantActive;

                let str = this.encodeUriParams(obj);
                // console.log(tujuan);
                vx.setLinkTelekonsul(`${baseUrl[0]}?${str}`);
                vx.setShowModalLinkTelekonsul(true);
                // window.open(`${baseUrl[0]}?${str}`)
            }
        },
        encryptData(pas) {
            const publicKey =
                "MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAK+i+DCC0/gHMonvl0SfsGwHJnsXJvDIY3xeKdR1VHTxr4lFGt/eMq6sQ40cSdyObW/c3fjnzkZIT/eo8Xy0+jMCAwEAAQ==";
            let jse = new JSEncrypt();
            jse.setPublicKey(publicKey);
            return jse.encrypt(pas);
        },
        encodeUriParams(obj) {
            var str = [];
            for (var p in obj) {
                if (obj.hasOwnProperty(p)) {
                    str.push(
                        encodeURIComponent(p) + "=" + encodeURIComponent(obj[p])
                    );
                }
            }
            return str.join("&");
        },
        showModal() {
            if (!this.selectedSlot) {
                toastr.error("Belum Memilih Slot");
            } else {
                let vx = this;
                let instance = new SweetAlert().showConfirm({
                    swalTitle: `<p>Status pasien <strong>Belum Datang</strong>, Apakah anda yakin untuk menjalankan "${
                        vx.actionsEnum[0]
                    }"? </p>`,
                    swalText: "Perubahan Data atau Status akan tersimpan.",
                    swalType: "info",
                    onConfirmButton: function() {
                        blockUI.blockModal();
                        vx.setShowModalPindah(true);
                    },
                    onCancelButton: function() {
                        blockUI.unblockModal();
                    }
                });
            }
        },
        openModal() {
            this.$refs.modal.open();
        },
        updateQueue(value, type) {
            let vx = this;
            const data = {
                note: value.note,
                queueId: null,
                uniqueId: vx.queueDevice.uniqueId,
                unitId: vx.jadwal.id,
                prevQueueId: value.id
            };
            if (value.queue) {
                data.queueId = value.queue.id;

                if (value.queue.reservasi.status !== "ARRIVED") {
                    vx.permission = false;
                } else {
                    vx.permission = true;
                }
            } else {
                data.queueId = value.id;
                vx.permission = true;
            }

            if (vx.permission) {
                vx.updateQueueStatus({
                    data,
                    type
                });
            } else {
                let instance = new SweetAlert().showConfirm({
                    swalTitle: `<p>Status pasien <strong>Belum Datang</strong>, Apakah anda yakin untuk menjalankan "${
                        vx.actionsEnum[type]
                    }"? </p>`,
                    swalText: "Perubahan Data atau Status akan tersimpan.",
                    swalType: "info",
                    onConfirmButton: function() {
                        blockUI.blockModal();
                        vx.updateQueueStatus({
                            data,
                            type
                        });
                    },
                    onCancelButton: function() {
                        blockUI.unblockModal();
                    }
                });
            }
        },
        checkPrint(num) {
            this.$emit("checkPrint", num);
        },
        onInit() {
            let device =
                sessionStorage.getItem("queueDevice") == undefined
                    ? new QueueDevice()
                    : { ...JSON.parse(sessionStorage.getItem("queueDevice")) };
            device.dokters = [];
            if (sessionStorage.getItem("selectedUnit") != undefined) {
                const selectedUnit = sessionStorage.getItem("selectedUnit");
                this.setSelectedUnit(selectedUnit);
                device.queueUnits = [
                    {
                        id: `U${selectedUnit}`
                    }
                ];
            }
            const callerName =
                sessionStorage.getItem("doctorCallerName") != undefined
                    ? sessionStorage.getItem("doctorCallerName")
                    : this.doctorCallerName;
            this.setDoctorCallerName(callerName);
            if (device.id == null) {
                device.notificationId =
                    this.userNotification != null
                        ? this.userNotification.notificationId
                        : null;
                device.uniqueId = this.sessionId;
                device.friendlyName = callerName;
            }
            this.saveQueueDevice(device);
        }
    },
    mounted() {
        setInterval(() => {
            this.nowTime = new Date();
        }, 1000);
        this.onInit();
        if (localStorage.defaultPrinter) {
            this.setDefaultPrinter(localStorage.defaultPrinter);
        }
    }
};
</script>
