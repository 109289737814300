<template>
    <v-breakpoint>
        <div slot-scope="scope">
            <modal-whatsapp v-if="showModalWA"></modal-whatsapp>
            <div>
                <header-doctor-caller @clickToggle="toggle"></header-doctor-caller>
                <div class="row mt-3">
                    <div class="col-12 col-lg-6" :class="(scope.isMedium || scope.noMatch || scope.isSmall) ? 'order-last': '' ">
                        <time-line-doctor-caller
                        id="thisOne"
                        ></time-line-doctor-caller>
                    </div>
                    <div class="col-12 col-lg-6 mb-4">
                        <div class="d-flex flex-column">
                            <control-doctor-caller @checkPrint="checkPrint"
                            @clicked="onClickChild"
                            ></control-doctor-caller>
                            <div class="row border rounded mt-2" v-if="scope.isLarge || scope.isXlarge">
                                <div class="col-12 p-3">
                                    <select-jadwal-doctor-caller></select-jadwal-doctor-caller>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DivToPrint
                    :detailPrint="detailPrint"
                    ref="detailPrint"
                ></DivToPrint>
                <ModalPindah
                    v-if="showModalPindah"
                    :value="selectedSlot"
                ></ModalPindah>
                <ModalHelp
                    v-if="showModalHelp"
                ></ModalHelp>
                <ModalPrinter
                    v-if="showModalPrinter"
                    :payload="payloadPrint"
                    @print="print"
                ></ModalPrinter>
                <ModalLinkTelekonsul v-if="showModalLinkTelekonsul"></ModalLinkTelekonsul>
            </div>
        </div>
    </v-breakpoint>
</template>

<script>
    import { mapState, mapMutations, mapActions } from 'vuex';
    import HeaderDoctorCaller from './_components/HeaderDoctorCaller.vue';
    import ControlDoctorCaller from './_components/ControlDoctorCaller.vue';
    import { VBreakpoint } from 'vue-breakpoint-component';
    import SelectJadwalDoctorCaller from './_components/PilihJadwalDoctorCaller.vue';
    import TimeLineDoctorCaller from './_components/TimeLineDoctorCaller.vue';
    import LoadSpinner from "./../../_general/LoadSpinner";
    import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
    const ServerTimestampRepository = RepositoryFactory.get("serverTimestamp");
    const QueueResource = RepositoryFactory.get("queueUser");
    const QueueUserRepository = RepositoryFactory.get("queueUser");


    import VueQrcode from 'vue-qrcode'
    import DivToPrint from '../../_general/DivToPrint.vue'
    import qz from "qz-tray";
    import { Printd } from "printd";
    const { RSAKey, KJUR, KEYUTIL, stob64, hextorstr } = require('jsrsasign');
    import CheckPermission from "./../../../services/checkPermission.service";
    const checkPermission = new CheckPermission();
    export default {
        components: {
            HeaderDoctorCaller,
            ControlDoctorCaller,
            VBreakpoint,
            SelectJadwalDoctorCaller,
            TimeLineDoctorCaller,
            VueQrcode,
            DivToPrint,
            ModalPindah: () => ({
                component: new Promise(function (resolve, reject) {
                    setTimeout(function () {
                        resolve(import(/* webpackChunkName: "modal-pindah" */ "./ModalPindah/ModalPindah"));
                    }, 1000);
                }),
                loading: LoadSpinner,
            }),
            ModalHelp: () => ({
                component: new Promise(function (resolve, reject) {
                    setTimeout(function () {
                        resolve(import(/* webpackChunkName: "modal-help" */ "./_components/ModalHelp"));
                    }, 1000);
                })
            }),
            ModalPrinter: () => ({
                component: new Promise(function (resolve, reject) {
                    setTimeout(() => {
                        resolve(import(/* webpackChunkName: "modal-printer" */ "../../_select/SelectPrinter.vue"))
                    }, 1000)
                })
            }),
            ModalLinkTelekonsul: () => ({
                component: new Promise(function (resolve, reject) {
                    setTimeout(function () {
                    resolve(
                        import(
                        /* webpackChunkName: "modal-reservation-mr-reg" */ "../DoctorCallers/_components/ModalLinkTelekonsul.vue"
                        )
                    );
                 }, 1000);
                })
            }),
            ModalWhatsapp: () => ({
            component: new Promise(function(resolve, reject) {
                setTimeout(() => {
                    resolve(
                        import("./../../doctor-appointments/DayCalendar/_components/ModalWhatsapp.vue")
                    );
                }, 500);
            })
        })
        },
        computed: {
             ...mapState('DoctorCallerStore', {
                selectedDate: state => state.selectedDate,
                serverTime: state => state.serverTime,
                showModalPindah: state => state.showModalPindah,
                selectedSlot: state => state.selectedSlot,
                showModalHelp: state => state.showModalHelp,
                jadwal: state => state.jadwal,
                showModalLinkTelekonsul: state => state.showModalLinkTelekonsul,
            }),
            ...mapState('CallerStore', {
                defaultPrinter: state => state.defaultPrinter,
                showModalPrinter: state => state.showModalPrinter,
                printingOption: state => state.printingOption,

            }),
            ...mapState('DayCalendarStore', {
                showModalWA: state => state.showModalWA,
            })
        },
        data() {
            return {
                detailPrint: {
                    displayName1: "",
                    displayName2: "",
                    schedule: "",
                    queueNo: "",
                    date: "",
                    content: "empty"
                },
                payloadPrint: {
                    printD: false,
                    printSelected: null
                }
            }
        },
        methods: {
            ...mapActions({
                getDataDoctor: 'DoctorCallerStore/GET_DATA_DOCTOR',
                getServerTime: 'DoctorCallerStore/GET_SERVER_TIME',
                
            }),
            ...mapMutations({
                setSelectedSlot: "DoctorCallerStore/SET_SELECTED_SLOT",
                setServerTime: 'DoctorCallerStore/SET_SERVER_TIME',
                setLoading: "CallerStore/SET_LOADING",
                setDefaultPrinter: "CallerStore/SET_DEFAULT_PRINTER",
                setShowModalPrinter: "CallerStore/SET_SHOW_MODAL_PRINTER",
                setPrintingOption: "CallerStore/SET_PRINTING_OPTION",
                setPermissionWhatsapp: "DoctorAppointmentStore/SET_PERMISSION_WHATSAPP",
                setSettingPrinter: "CallerStore/SET_SETTING_PRINTER",
            }),
              onClickChild (value) {
                const el = document.getElementById("thisOne")
                    el.classList.add("nyala");
                setTimeout(() => {
                    el.classList.remove("nyala");
                }, 1000);
            },
            toggle() {
                this.$emit('clickToggle', true);
            },
            async checkPrint(num){
                let vx = this;
                vx.setLoading(true)
                if(vx.printingOption == 'SERVER'){
                    if(num == 1){
                        await helpers.checkPrinter().then(data => {
                            if(data.selectPrinter){
                                vx.setShowModalPrinter(true)
                                vx.setSettingPrinter(false)
                            } else {
                                vx.print({ printD: false })
                            }
                        })
                        .catch(err => {
                                vx.print({ printD: true })
                        })
    
                    } else {
                        if(vx.selectedSlot.queue){
                            vx.payloadPrint.printSelected = vx.selectedSlot.queue.id
                        } else {
                            vx.payloadPrint.printSelected = vx.selectedSlot.id
                        }
                        await helpers.checkPrinter().then(data => {
                            if(data.selectPrinter){
                                vx.setShowModalPrinter(true)
                            } else {
                                vx.printSelectedQueue(vx.payloadPrint)
                            }
                        })
                        .catch(err => {
                            vx.payloadPrint.printD = true
                            vx.printSelectedQueue(vx.payloadPrint)
                        })
                    }
                } else {
                    vx.payloadPrint.printD = true
                    if (num == 1){
                        vx.print(vx.payloadPrint)
                    } else {
                        if(vx.selectedSlot.queue){
                            vx.payloadPrint.printSelected = vx.selectedSlot.queue.id
                        } else {
                            vx.payloadPrint.printSelected = vx.selectedSlot.id
                        }
                        vx.printSelectedQueue(vx.payloadPrint)
                    }
                }
            },
            async print({ printD }){
                let vx = this;
                let newPatient = {
                    "date": vx.selectedDate,
                    "queueType": "MANUAL",
                    "queueUnitId": `J${vx.jadwal.id}`,
                }

                let response =  await QueueResource.createQueueUserAuto(newPatient)
                vx.detailPrint.displayName1 = response.data.queueUnit.displayName1;
                vx.detailPrint.displayName2 = response.data.queueUnit.displayName2;
                vx.detailPrint.schedule = `(${helpers.daysToId(response.data.queueUnit.jadwal.hari)}, ${response.data.queueUnit.jadwal.jamMulai} - ${response.data.queueUnit.jadwal.jamSelesai})`
                vx.detailPrint.queueNo = response.data.queueNoFormatted;
                vx.detailPrint.content = `{"content": "${response.data.encryptedId}","type":"antrian"}`

                await ServerTimestampRepository.get()
                    .then(response => {
                        var dateFormattedId = moment(response.data).format(
                            "dddd, D MMMM YYYY HH:mm:ss"
                        );
                        vx.detailPrint.date = dateFormattedId;
                    })

                let payload = {
                    printD : false
                }
                if (vx.printingOption == 'DIALOG') {
                    payload.printD = true
                }
                this.$refs.detailPrint.printQueue(payload)
//              const printDiv = document.getElementById('divToPrint')
            },
            async printSelectedQueue(payload){
                let vx = this;
                await QueueUserRepository.getQueueUser(payload.printSelected)
                    .then(async response => {
                        vx.detailPrint.displayName1 = response.data.queueUnit.displayName1;
                        vx.detailPrint.displayName2 = response.data.queueUnit.displayName2;
                        if(response.data.queueUnit.jadwal){
                            vx.detailPrint.schedule =
                            "(" +
                            helpers.daysToId(response.data.queueUnit.jadwal.hari) +
                            ", " +
                            response.data.queueUnit.jadwal.jamMulai +
                            " - " +
                            response.data.queueUnit.jadwal.jamSelesai +
                            ")";
                        }
                        vx.detailPrint.queueNo = response.data.queueNoFormatted;
                        vx.detailPrint.content = `{"content": "${response.data.encryptedId}","type":"antrian"}`
                    })

                await ServerTimestampRepository.get()
                    .then(response => {
                        var dateFormattedId = moment(response.data).format(
                            "dddd, D MMMM YYYY HH:mm:ss"
                        );
                        vx.detailPrint.date = dateFormattedId;
                    })
                let payl = {
                    printD : false
                }
                if (vx.printingOption == 'DIALOG') {
                    payl.printD = true
                }
                this.$refs.detailPrint.printSelectedQueue(payl)
    //          const printDiv = document.getElementById('divToPrint')
            },
            checkPermission: function (method) {
                return checkPermission.isCanAccess(method);
            },
        },
        mounted () {
            this.setSelectedSlot(null)
            this.setPermissionWhatsapp(this.checkPermission('Booking.Whatsapp.Send.Active'))
            if(localStorage.defaultPrinter){
                this.setDefaultPrinter(localStorage.defaultPrinter)
            }
            if(localStorage.printingOption){
                this.setPrintingOption(localStorage.printingOption)
            }
            this.getServerTime()
        },
    }
</script>

<style lang="scss" scoped>

</style>
