<template>
    <div>
        <vue-multi-select
            :options="listOptions"
            v-model="selectedValue"
            :multiple="multiple"
            :trackBy="trackBy"
            :label="label"
            :group-label="groupLabelSelect"
            :group-select="groupSelect"
            :group-values="groupValueSelect"
            :internal-search="internalSearch"
            :search-change="searchChangeSelect"
            :select="select"
            :remove="remove"
            :validation="validation"
            :validationName="validationName"
            :name="name"
            :scope="scope"
            :placeholder="placeholder"
            :loading="useLoading"
            :valueLabel="valueLabel"
            :disabled="disabled"
            :isNotShowSelectedTags="isNotShowSelectedTags"
            :isShowSearchNote="isShowSearchNote"
            :isCloseOnSelect="isCloseOnSelect"
            :isClearOnSelect="isClearOnSelect"
        >
            <template v-slot:selectionTemplate="{data}">
                <slot name="selectionTemplateSlot" :data="data">
                </slot>
            </template>
            <template v-slot:optionsTemplate="{data}" v-if="isShowTujuan">
               <div class="option__desc">
                    <span class="option__title d-block">{{ data.option.nama }}</span>
                    <span class="option__small d-block fs-9"> ( {{ data.option.tujuanRujukan ? data.option.tujuanRujukan.nama : '-' }} )</span>
            </div> 
            </template>
        </vue-multi-select>
    </div>
</template>

<script>
    import { RepositoryFactory } from "./../../repositories/RepositoryFactory";
    const DoctorRepository = RepositoryFactory.get("doctor");
    import VueMultiSelect from './VueMultiSelect.vue';
    export default {
        provide() {
            return {
                $validator: this.$validator
            };
        },
        inject: ["$validator"],
        data() {
            return {
                responseData: [],
                optionDoctors: [],
                cloneOptions: [],
                localLoading: false,
            }
        },
        components: {
            VueMultiSelect,
        },
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: [Array, Number, String, Object],
            options: {
                type:Array
            },
            grouping: {
                type: Boolean,
                default: false
            },
            multiple:{
                type: Boolean,
                default: false
            },
            trackBy: {
                type: String,
                default: 'id'
            },
            label:{
                type: String,
                default: 'nama'
            },
            groupLabel:{
                type: String,
                default: 'nama'
            },
            groupSelect: {
                type: Boolean,
                default: false
            },
            groupValue: {
                type: String,
                default: 'doctors'
            },
            validationName:{
                type:String,
                default:'Doktor'
            },
            validation:{
                type: [String, Object],
                default:''
            },
            name:{
                type:String,
                default: 'doktor'
            },
            scope: {
                type: String,
                default:null
            },
            select:{
                type: Function,
                default:() => {}
            },
            remove:{
                type: Function,
                default:() => {}
            },
            internalSearch: {
                type: Boolean,
                default: true
            },
            searchChange: {
                type: Function
            },
            placeholder:{
                type: String
            },
            valueLabel:{
                type: String
            },
            disabled:{
                type:Boolean,
                default:false
            },
            params: {
                type: Object,
                default: () => { return {} }
            },
            loading: {
                type: Boolean,
                default: undefined
            },
            defaultValue: {
                type: Boolean,
                default: undefined
            },
            nullSelect: {
                type: Boolean,
                default: false
            },
            isShowTujuan: {
                type: Boolean,
                default: false
            },
            isNotShowSelectedTags: {
                type: Boolean,
                default: false
            },
            isShowSearchNote: {
                type: Boolean,
                default: false
            },
            isCloseOnSelect: {
                type: Boolean,
                default: true
            },
            isClearOnSelect: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            selectedValue: {
                get () {
                    return this.value
                },
                set (newValue) {
                    this.$emit('change', newValue);
                }
            },
            listOptions:function() {
                let objStr = ''
                if (this.options ) {
                    objStr = 'options'
                }else if (this.optionDoctors){
                    objStr = 'optionDoctors'
                }

                return this[objStr].map(item => {
                    // console.log(item , "item listOptions");
                    const newItem = {
                        ...item,
                        tujuanRujukan: this.defaultSpesialisasi(item.dokterSpesialisasis)
                    }
                    return newItem
                })
                // return (this.options != undefined) ? this.options : this.optionDoctors;
            },
            groupLabelSelect: function(){
                return (this.grouping) ? this.groupLabel : undefined
            },
            groupValueSelect: function(){
                 return (this.grouping) ? this.groupValue : undefined
            },
            searchChangeSelect: function(){
                if(this.grouping){
                    return (this.searchChange != undefined) ? this.searchChange : this.searchChangeGroup;
                } else {
                    return this.searchChange;
                }
            },
            useLoading: function(){
                return (this.loading != undefined) ? this.loading : this.localLoading;
            }
        },
        methods: {
            async getData(){
                try{
                    this.localLoading = true;
                    var data = [];
                    const response = await new Promise((resolve, reject) => {
                        DoctorRepository.fetchAll(this.params, resolve, reject);
                    });
                    if(this.grouping){
                        response.data.forEach(x => {
                            if(x.tujuanRujukan) {
                                var index = data.findIndex(y => y.id == x.tujuanRujukan.id);
                                if(index < 0){
                                    const poly = {...x.tujuanRujukan};
                                    poly.doctors = []
                                    data.push(poly);
                                    index = data.length - 1;
                                }
                                data[index].doctors.push(x);
                            }
                        })
                        this.responseData = response.data;
                        this.optionDoctors = data;
                        this.cloneOptions = [...this.optionDoctors];
                    } else {
                        this.optionDoctors = response.data;
                    }
                    if(this.defaultValue && !this.nullSelect) {
                        this.selectedValue = this.optionDoctors[0]
                    }
                    this.localLoading=false;
                } catch (err){
                    var msg = (error.message) ? error.message : error;
                    toastr.error('Doktor : ' + msg);
                }
            },
            searchChangeGroup(query){
                this.optionDoctors = this.cloneOptions.filter(x => {
                    if(x.doctors.find(y => y.nama.toLowerCase().indexOf(query.toLowerCase()) > -1 )){
                        return x
                    } else if ( x.nama ) {
                        if ( x.nama.toLowerCase().indexOf(query.toLowerCase()) > -1 )  {
                            return x;
                        }
                    }
                })
            },
            defaultSpesialisasi(array){
                if (Array.isArray(array)) {
                    for (let i = 0; i < array.length; i++) {
                        if (array[i].isDefault || array[i].default) {
                            if (array[i].spesialisasi) {
                                return this.spesialisasiMapper(array[i].spesialisasi)
                            }
                        }
                    }
                }
            },
            spesialisasiMapper(spesialisasi){
                if(spesialisasi){
                    return {
                        id: spesialisasi.ID,
                        nama: spesialisasi.namaIndo
                    }
                }
            }
        },
        mounted () {
            if (this.options == undefined) {
                this.getData();
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
