<template>
    <div
        class="doctor-caller-slot rounded h-100"
        @click="eventClick"
        :id="`slot-${value.id}`"
        :class="[{ active: isActive }, getBackgroundColor]"
    >
        <div :id="`body-slot-${value.id}`">
            <template v-if="value.queueStatus == 'TELEKONSUL'">
                <span style="position: absolute; top: 10px; right: 10px">
                    TELEKONSUL &nbsp;<i :class="getClass"></i>
                </span>
            </template>
            <template v-else>
                <i
                    :class="getClass"
                    style="position: absolute; top: 10px; right: 10px"
                ></i>
            </template>
            <div class="d-flex">
                <div>{{ value.queueNoFormatted }}</div>
            </div>
            <div class="" v-if="value.queue">
                <div>{{ value.queue.reservasi.nama }}</div>
                <div v-if="value.queue.reservasi.nomorMedicalRecord">
                    {{ value.queue.reservasi.nomorMedicalRecord }}
                </div>
                <div v-if="value.queue.reservasi.nomorRegistrasi">
                    {{ value.queue.reservasi.nomorRegistrasi }}
                </div>
            </div>
            <div class="d-flex">
                <div>{{ start | filterDate }} - {{ end | filterDate }}</div>
            </div>
            <div class="d-block" v-if="value.queue && value.queue.reservasi">
                <template
                    v-if="
                        xVue.state.DoctorAppointmentStore.isPermissionWhatsapp
                    "
                >
                    <p
                        title="send to WA"
                        class="mb-2 "
                       
                    >
                    <span class="show-bigger"  @click="WAme(value.queue.reservasi.telp)">
                        {{ value.queue.reservasi.telp }}
                    </span>
                        
                    </p>
                </template>
                <template v-else>
                    <p class="mb-2">{{ value.queue.reservasi.telp }}</p>
                </template>
                <div>{{ value.queue.reservasi.pesan }}</div>
            </div>
        </div>

        <b-tooltip
            :target="`slot-${value.id}`"
            triggers="hover"
            v-if="
                xVue.state.DoctorAppointmentStore.isPermissionWhatsapp && isShow
            "
        >
            <div class="row text-left">
                <template v-if="value.queueStatus == 'TELEKONSUL'">
                    <span style="position: absolute; top: 10px; right: 10px">
                        TELEKONSUL &nbsp;<i :class="getClass"></i>
                    </span>
                </template>
                <template v-else>
                    <i
                        :class="getClass"
                        style="position: absolute; top: 10px; right: 10px"
                    ></i>
                </template>
                <div class="d-block">
                    <div>{{ value.queueNoFormatted }}</div>
                </div>
                <div
                    class="d-block"
                    v-if="value.queue"
                    style="min-width: 300px !important"
                >
                    <div>{{ value.queue.reservasi.nama }}</div>
                    <div v-if="value.queue.reservasi.nomorMedicalRecord">
                        {{ value.queue.reservasi.nomorMedicalRecord }}
                    </div>
                    <div v-if="value.queue.reservasi.nomorRegistrasi">
                        {{ value.queue.reservasi.nomorRegistrasi }}
                    </div>
                </div>
                <div class="d-block">
                    <div>{{ start | filterDate }} - {{ end | filterDate }}</div>
                </div>
                <div
                    class="d-block"
                    v-if="value.queue && value.queue.reservasi"
                >
                    <p
                        title="send to WA"
                        class="d-block mb-2 show-bigger pointer"
                        @click="WAme(value.queue.reservasi.telp)"
                    >
                        {{ value.queue.reservasi.telp }}
                    </p>
                    <p class="d-block">{{ value.queue.reservasi.pesan }}</p>
                </div>
            </div>
        </b-tooltip>
    </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
    props: {
        xVue: {
            type: Object
        },
        value: {
            type: Object
        },
        start: {
            type: Date
        },
        end: {
            type: Date
        }
    },
    data() {
        return {
            isActive: false,
            isShow: false
        };
    },
    filters: {
        filterDate: function(value) {
            return moment(value).format("HH:mm");
        }
    },
    methods: {
        checkHeight() {
            const slotDiv = `#body-slot-${this.value.id}`;
            const heightDiv = $(slotDiv).height();
            const eventHeight = $(".fc-time-grid-event").height();
            if (heightDiv > eventHeight) return true;
            return false;
        },
        eventClick() {
            this.xVue.commit("DoctorCallerStore/SET_SELECTED_SLOT", this.value);
        },
        WAme(numberWA) {
            this.xVue.commit("DayCalendarStore/SET_SHOW_MODAL_WA", true);
            this.xVue.commit("DayCalendarStore/SET_NUMBER_WA", numberWA);
        }
    },
    computed: {
        getClass() {
            switch (this.value.queueStatus) {
                case "SERVING":
                    return "fas fa-user-clock";
                case "CALLING":
                    return "fas fa-bullhorn";
                case "DONE":
                    return "fas fa-user-check";
                case "SKIPPED":
                    return "fas fa-user-times";
                case "CHECKEDIN":
                    return "fas fa-user";
                case "NEW":
                    return "far fa-user";
                case "CANCEL":
                    return "fas fa-ban";
                case "TELEKONSUL":
                    return "fas fa-headset";
                case "DEFECT":
                    return "fas fa-exclamation-triangle";
                default:
                    return "fas fa-cloud";
            }
        },
        getStyle() {
            return {
                position: "absolute",
                top: "10px",
                right: "10px"
            };
        },
        events() {
            return this.xVue.state.DoctorCallerStore.selectedSlot;
        },
        getBackgroundColor() {
            switch (this.value.queueStatus) {
                case "SERVING":
                    return "bg-serving";
                case "CALLING":
                    return "bg-calling";
                case "DONE":
                    return "bg-done";
                case "SKIPPED":
                    return "bg-skipped";
                case "CHECKEDIN":
                    return "bg-checkedin";
                case "NEW":
                    return "bg-checkedin";
                case "CANCEL":
                    return "bg-cancel";
                case "TELEKONSUL":
                    return "bg-checkedin";
                case "DEFECT":
                    return "bg-defect";
                default:
                    return "bg-checkedin";
            }
        }
    },
    watch: {
        events(val) {
            const valId = val ? val.id : "";
            if (valId == this.value.id) {
                this.isActive = true;
            } else {
                this.isActive = false;
            }
        }
    },
    mounted() {
        // MOCK TELEKONSUL
        if (this.value.queue && this.value.queue.reservasi.teleconsultPayment) {
            this.value.queueStatus = "TELEKONSUL";
        }
        setTimeout(() => {
            this.isShow = this.checkHeight();
        }, 1000);
    }
};
</script>
<style lang="scss" scoped></style>
