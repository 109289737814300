<template>
    <div>
        <div class="row">
            <div class="col-12 text-right">
                <button type="button" class="btn btn-default btn-elevate btn-circle btn-icon"  @click="toggle">
                    <i class="fas fa-expand"></i>
                </button>
            </div>
        </div>
        <div class="row justify-content-md-center align-items-center" style="height:85vh">
            <div class="col-md-auto" >
                <h3 class="text-center mb-5">Pilih Caller</h3>
                <button type="button" @click="showCaller(callerType.UNIT_CALLER)" class="btn btn-lg btn-default btn-boldest d-block w-100 mb-3"
                v-if="checkPermission('AllowUnitCaller')"
                >
                    UNIT CALLER
                </button>
                <button type="button" @click="showCaller(callerType.DOCTOR_CALLER)" class="btn btn-lg btn-default btn-boldest d-block w-100"
                v-if="checkPermission('AllowDokterCaller')"
                >
                    DOCTOR CALLER
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapMutations, mapActions } from 'vuex';
    import CallerType from "./../../constants/caller-type-enum";
    import CheckPermission from "./../../services/checkPermission.service";
    const checkPermission = new CheckPermission();
    export default {
        data() {
            return {
                callerType: CallerType
            }
        },
        methods: {
            showCaller(type){
                this.setViewCaller(type);
                if(type == 'UNIT_CALLER') {
                    this.setSelectedUnit('')
                    this.setUnitQueue(null)
                }
            },
            ...mapMutations({
                setViewCaller: 'CallerStore/SET_VIEW_CALLER',
                setUnitQueue: 'CallerStore/SET_UNIT_QUEUE',
                setQueueUnitPass: 'DoctorCallerStore/SET_QUEUE_UNIT_PASSWORD',
                setDoctorLogin: 'DoctorCallerStore/SET_DOCTOR_LOGIN',
                setSelectedUnit: 'UnitCallerStore/SET_SELECTED_UNIT'
                // 
            }),
            toggle() {
                this.$emit('clickToggle', true);
            },
            checkPermission: function(method, menuShow) {
            const validPermission = checkPermission.isCanAccess(method);
            return validPermission;
            },
        },
        mounted(){
            if (this.checkPermission('AllowDokterCaller') && !this.checkPermission('AllowUnitCaller')) {
                this.showCaller(this.callerType.DOCTOR_CALLER)
            }else if (!this.checkPermission('AllowDokterCaller') && this.checkPermission('AllowUnitCaller')){
                this.showCaller(this.callerType.UNIT_CALLER)
            }

            if (this.checkPermission('QueueUnitResource.GET.QueueUnitSlot.Password')) {
                this.setQueueUnitPass(true)
            }
            let doctorLogin = this.checkPermission('DokterResource.GET.Dokter.Current')
            if(doctorLogin) {
                this. setDoctorLogin(doctorLogin)
            }
        }
        
    }
</script>

<style lang="scss" scoped>

</style>
