<template>
   <div
        id="doctorControl"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
        <div class="modal-dialog modal-md modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Control Dokter</h5>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        @click="close()"
                    ></button>
                </div>
                <div class="modal-body">
                    <select-jadwal-doctor-caller></select-jadwal-doctor-caller>
                </div>
            </div>
        </div>
   </div>
</template>

<script>
    import { mapState, mapMutations, mapActions } from 'vuex';
    import SelectJadwalDoctorCaller from './PilihJadwalDoctorCaller.vue';
    export default {
        components: {
            SelectJadwalDoctorCaller
        },
        computed: {
            ...mapState('DoctorCallerStore', {
                selectedDate: state => state.selectedDate,
            }),
        },
        methods: {
            close() {
                $("#doctorControl").modal("hide");
            },
            open() {
                 $("#doctorControl").modal();
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
