<template>
    <!-- <v-breakpoint>
        <div slot-scope="scope"> -->
            <div class="row mb-3">
                <div class="row col-md-4 col-sm-4 my-2">
                    <button class="btn btn-default btn-elevate btn-circle btn-icon mr-2" @click="showCaller(callerType.OPTION_CALLER)">
                        <i class="fas fa-arrow-left"></i>
                    </button>
                    <div v-if="editCaller">
                        <div class="input-group">
                            <input type="text" class="form-control" v-model="callerName" placeholder="Nama Caller">
                            <div class="input-group-append">
                                <button class="btn btn-default btn-bold" type="button"  @click="updateFriendlyName()">
                                    <i class="fa fa-save"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="kt-font-bold kt-font-xl mt-2" v-else>
                        <span>{{callerName}} </span>
                        <button type="button"
                            class="btn btn-outline-hover-primary btn-sm btn-icon btn-circle"
                            @click="editCaller = true"
                        >
                            <i class="fa fa-edit kt-font-primary"></i>
                        </button>
                    </div>
                    <div class="col-md-auto col-sm-auto col-auto my-auto">
                        <div class="d-flex mt-2">
                            <div class="kt-font-bold kt-font-md">
                                <span>{{ date }}</span> 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-8 col-sm-8 row pr-0 flex-row-reverse">
                    <div class="col-md-auto col-sm-auto col-auto mb-2 pr-0">
                        <button class="btn btn btn-default btn-elevate btn-circle btn-icon mr-2" @click="configPrinter">
                            <i class="fas fa-print"></i>
                            <i class="fas fa-cog" style="margin-left: 2px"></i>
                        </button>
                        <button class="btn btn btn-default btn-elevate btn-circle btn-icon mr-2" @click="showHistory">
                            <i class="fas fa-history"></i>
                        </button>
                        <button class="btn btn-default btn-elevate btn-circle btn-icon mr-2" @click.prevent="refreshData">
                            <i class="flaticon-refresh"></i>
                        </button>
                        <button type="button" class="btn btn-default btn-elevate btn-circle btn-icon mr-2" @click="showHelp()" >
                            <i class="fas fa-question"></i>
                        </button>
                        <button class="btn btn-default btn-elevate btn-circle btn-icon" @click="toggle" >
                            <i class="fas fa-expand"></i>
                        </button>
                    </div>
                    <div class="col-md-3 col-sm-3 col-4 mb-2 pr-0">
                        <unit-select
                            ref="unitSelect"
                            v-model="unit"
                            :value-label="'id'"
                            :options="options"
                            :loading="loading"
                            :allowEmpty="false"
                        ></unit-select>
                        <p v-if="!unit" style="color: red;">Silahkan Pilih Unit</p>
                    </div>
                </div>
            </div>
        <!-- </div>
    </v-breakpoint> -->
</template>

<script>
    import UnitSelect from "./../../../_select/Unit";
    import { mapState, mapMutations, mapActions } from 'vuex';
    import CallerType from "./../../../../constants/caller-type-enum";
    import { VBreakpoint } from 'vue-breakpoint-component';
    import qz from 'qz-tray'

    export default {
        components: {
            UnitSelect,
            VBreakpoint,
        },
        data() {
            return {
                editCaller: false,
                callerType: CallerType,
                date: String
            }
        },
         computed: {
            ...mapState('UnitCallerStore', {
                unitCallerName: state => state.unitCallerName,
                unitId: state => state.unit,
                options: state => state.unitOptions,
                loading: state => state.loadingUnit,
            }),
            ...mapState('CallerStore', {
                queueDevice: state => state.queueDevice,
                userNotification: state => state.userNotification,
            }),
            callerName: {
                get: function () {
                    return this.unitCallerName;
                },
                set: function (newValue) {
                   this.setUnitCallerName(newValue);
                }
            },
            unit: {
                get: function () {
                    return this.unitId;
                },
                set: function (newValue) {
                   this.setSelectedUnit(newValue);
                   this.setSelectedQueue()
                   this.changeQueueDevice(newValue);
                }
            },
            unitObject: function(){
                return this.$refs.unitSelect.listOption.find(x => x.id == this.unit);
            }
        },
        methods: {
             ...mapMutations({
                setUnitCallerName: 'UnitCallerStore/SET_UNIT_CALLER_NAME',
                setSelectedUnit: 'UnitCallerStore/SET_SELECTED_UNIT',
                setViewCaller: 'CallerStore/SET_VIEW_CALLER',
                setShowHistory: 'UnitCallerStore/SET_SHOW_HISTORY',
                setShowModalPrinter: "CallerStore/SET_SHOW_MODAL_PRINTER",
                setSettingPrinter: "CallerStore/SET_SETTING_PRINTER",
                setSelectedQueue: 'UnitCallerStore/SET_SELECTED_QUEUE',
                setShowModalHelp: 'UnitCallerStore/SET_SHOW_MODAL_HELP',
            }),
            ...mapActions({
                getListUnitOption: 'UnitCallerStore/GET_ALL_UNITS_OPTION',
                saveQueueDevice: 'CallerStore/SAVE_QUEUE_DEVICE',
                getDataFromApi: 'UnitCallerStore/GET_DATA_FROM_API'
            }),
            showCaller(data){
                this.setViewCaller(data)
            },
            toggle() {
                this.$emit('clickToggle', true);
            },
            changeQueueDevice(unitId){
                const device = {...this.queueDevice};
                device.dokters = [];
                device.queueUnits = [{
                    id: `U${unitId}`
                }];
                device.notificationId = this.userNotification.notificationId;
                this.saveQueueDevice(device);
                this.getDataFromApi(unitId)
            },
            showHistory(){
                if (!this.unit) {
                    toastr.error('Silahkan Memilih Unit');
                }else{
                    this.setShowHistory(true)
                }
            },
            refreshData() {
                this.getDataFromApi(sessionStorage.getItem("selectedUnit"))
            },
            updateFriendlyName(){
                const device = {...this.queueDevice};
                device.friendlyName = this.unitCallerName
                this.saveQueueDevice(device);
                this.editCaller = false
            },
            configPrinter(){
                this.setSettingPrinter(true)
                this.setShowModalPrinter(true)
            },
            showHelp(){
                this.setShowModalHelp(true)
            }
        },
        mounted () {
            this.getListUnitOption();
            const unitId = sessionStorage.getItem("selectedUnit")
            this.date = moment().format("dddd, D MMMM YYYY");
            this.getDataFromApi(unitId)
        },
    }
</script>

<style lang="scss" scoped>

</style>
