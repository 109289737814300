<template>
    <div>
        <div class="row border-bottom mb-4 pb-2 ">
            <div class="col-11">
                <h3>RECENTLY OPENED CALLER ( TOP 5 RECENT )</h3>
            </div>
            <div class="col-1 text-right">
                <button
                    type="button"
                    class="btn btn-default btn-elevate btn-circle btn-icon"
                    @click="toggle"
                >
                    <i class="fas fa-expand"></i>
                </button>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-6" v-if="historyUnitCaller.length > 0 && checkPermission('AllowUnitCaller')">
                <h5>Unit Caller</h5>
                <table class="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Opened unit</th>
                            <th>Counter Name</th>
                            <th>Last open</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(unit, index) in historyUnitCaller"
                            :key="index"
                        >
                            <td>
                                <label class="kt-radio">
                                    <input
                                        type="radio"
                                        :value="unit"
                                        v-model="selectedOpen"
                                    />
                                    <span></span>
                                </label>
                            </td>
                            <td>
                                {{
                                    unit.selectedUnit.displayName1 | filterNull
                                }}
                                {{
                                    unit.selectedUnit.displayName2 | filterNull
                                }}
                                <br /><span
                                    v-if="idTab == unit.sessionId"
                                    class="badge badge-secondary"
                                    style="font-size: .6rem;"
                                    >Current Tab</span
                                >
                            </td>
                            <td>{{ unit.callerName }}</td>
                            <td>{{ unit.lastUpdate | filterLastUpdate }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-6" v-if="historyDokterCaller.length > 0 && checkPermission('AllowDokterCaller')">
                <h5>Dokter Caller</h5>
                <table class="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th></th>
                            <th>List Bookmark</th>
                            <th>Selected Dokter</th>
                            <th>Last open</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(dokter, index) in historyDokterCaller"
                            :key="index"
                        >
                            <td>
                                <label class="kt-radio">
                                    <input
                                        type="radio"
                                        :value="dokter"
                                        v-model="selectedOpen"
                                    />
                                    <span></span>
                                </label>
                            </td>
                            <td>
                                {{ dokter.bookmarkDokter | filterBookmark }}
                                <br /><span
                                    v-if="idTab == dokter.sessionId"
                                    class="badge badge-secondary"
                                    style="font-size: .6rem;"
                                    >Current Tab</span
                                >
                            </td>
                            <td>{{ dokter.selectedDokter.nama }}</td>
                            <td>{{ dokter.lastUpdate | filterLastUpdate }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row justify-content-md-center align-items-center mt-5">
            <div class="col-md-auto">
                <button
                    type="button"
                    @click="openHistory"
                    class="btn btn-primary btn-boldest mr-3 "
                >
                    OPEN SELECTED CALLER
                </button>
                <button
                    type="button"
                    @click="newDokterCaller(callerType.OPTION_CALLER)"
                    class="btn btn-default btn-boldest"
                >
                    OPEN NEW CALLER
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import CallerType from "./../../constants/caller-type-enum";
import CheckPermission from "./../../services/checkPermission.service";
const checkPermission = new CheckPermission();
export default {
    data() {
        return {
            callerType: CallerType,
            selectedOpen: null,
            idTab: sessionStorage.getItem("sessionId")
        };
    },
    filters: {
        filterNull: function(value) {
            return value == null ? "" : value;
        },
        filterLastUpdate: function(value) {
            return moment(value, "YYYY-MM-DDTHH:mm:ss").format(
                "YYYY-MM-DD HH:mm:ss"
            );
        },
        filterBookmark: function(value) {
            return value.map(x => x.nama).join(", ");
        }
    },
    computed: {
        ...mapState("CallerStore", {
            historyDokterCaller: state => state.historyDokterCaller,
            historyUnitCaller: state => state.historyUnitCaller
        })
    },
    methods: {
        ...mapMutations({
            setViewCaller: "CallerStore/SET_VIEW_CALLER",
            setSelectedUnit: "UnitCallerStore/SET_SELECTED_UNIT",
            setUnitCallerName: "UnitCallerStore/SET_UNIT_CALLER_NAME"
        }),
        ...mapActions({
            getUnitQueue: "CallerStore/GET_UNIT_QUEUE"
        }),
        toggle() {
            this.$emit("clickToggle", true);
        },
        newDokterCaller(value) {
            this.setViewCaller(value);
            sessionStorage.removeItem("doktorCallerDokter");
            sessionStorage.removeItem("doktorCallerBookmarkDokter");
            sessionStorage.removeItem("selectedUnit");
            sessionStorage.removeItem("queueDevice");
        },
        setUnit() {
            const unit = this.selectedOpen.selectedUnit.id;
            this.getUnitQueue(unit);
            this.setSelectedUnit(parseInt(unit.substring(1, unit.length)));
            this.setViewCaller(CallerType.UNIT_CALLER);
            this.setUnitCallerName(this.selectedOpen.callerName);
        },
        setDokterCaller() {
            if (this.selectedOpen.bookmarkDokter.length > 0) {
                sessionStorage.setItem(
                    "doktorCallerBookmarkDokter",
                    this.selectedOpen.bookmarkDokter.map(x => x.id)
                );
            }
            if (this.selectedOpen.selectedDokter != null) {
                sessionStorage.setItem(
                    "doktorCallerDokter",
                    this.selectedOpen.selectedDokter.id
                );
            }
            this.setViewCaller(CallerType.DOCTOR_CALLER);
        },
        openHistory() {
            if (this.selectedOpen != null) {
                if (this.selectedOpen.type == CallerType.UNIT_CALLER) {
                    this.setUnit();
                } else {
                    this.setDokterCaller();
                }
            }
        },
        checkPermission: function(method, menuShow) {
            const validPermission = checkPermission.isCanAccess(method);
            return validPermission;
        }
    },
    mounted(){

    }
};
</script>

<style lang="scss" scoped></style>
