export default {
    PASIEN_BELUM_MASUK: 'PASIEN_BELUM_MASUK',
    PASIEN_DATANG: 'PASIEN_DATANG',
    TIDAK_JADI_DATANG: 'TIDAK_JADI_DATANG',
    DIRUJUK_KETEMPAT_LAIN: 'DIRUJUK_KETEMPAT_LAIN',
    DALAM_PERAWATAN: 'DALAM_PERAWATAN',
    PASIEN_PULANG: 'PASIEN_PULANG',
    DALAM_PROSES_PEMBAYARAN: 'DALAM_PROSES_PEMBAYARAN',
    SUDAH_TERBAYARKAN: 'SUDAH_TERBAYARKAN',
    CLOSED: 'CLOSED'
}
