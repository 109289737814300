<template>
  <v-server-table
        :columns="columns"
        :options="options" 
        ref="table"
        @loaded="onLoaded(0)"
        @loading="onLoaded(1)"
    >
        <div slot="action" slot-scope="{ row }">
            <btn-action :row-data="row"
            :room-vue="roomVue" :storeObject="$store"></btn-action>
        </div>
        <div v-if="isLoading" slot="afterBody" >
            <h6 class="txt"> loading..</h6>
        </div>
    </v-server-table>
</template>

<script>
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
const RoomDisplayRepository = RepositoryFactory.get("roomDisplay");
import BtnAction from "./../_components/BtnAction";


export default {
    name: 'room-server-table',
    components: {
        BtnAction
    },
    props: {
        roomVue: {
            type: Object
        }
    },
    data () {
        var vx = this
        return {
            tableHeaderClass: 'blocked',
            tableBodyClass: 'vuetable-semantic-no-top blocked',
            isLoading: false,
            columns: ['id', 'name','description','floor','dokter.nama', 'dokter.tujuanRujukan.nama','action'],
            options: {
                texts: {
                    filterBy: 'Cari',
                },
                filterable: ['name','floor', 'description', 'dokter.nama', 'dokter.tujuanRujukan.nama'],
                headings: {
                    id: 'ID',
                    name: "Nama",
                    decription: "Deskripsi",
                    floor: "Lantai",
                    "dokter.nama": "Nama Dokter",
                    "dokter.tujuanRujukan.nama": 'Tujuan Rujukan',
                    action: 'Actions'
                },
                dateColumns: ['createdDate', 'lastModifiedDate' ],
                toMomentFormat: 'YYYY-MM-DD',
                debounce: 700,
                requestFunction(data) {
                    let params = {};
                    this.isLoaded = false
                    params.page = data.page-1;
                    params.size = data.limit;
                    params.sort = 'id'
                    if(_.isEmpty(data.query)){
                        vx.modeSearch = false;
                        return RoomDisplayRepository.fetch(params).catch(function (e) {
                            this.dispatch('error', e);
                        });
                    }else{
                        // if (data.query["dokter.nama"]) {
                        //     data.query["dokter.nama"] = data.query["dokter.nama"].replace(/\./g,'/%2E%2E/')
                        // }
                        vx.modeSearch = true;
                        params.query = helpers.filterTable(data.query);
                        return RoomDisplayRepository.search(params).catch(function (e) {
                            vx.showToastError('Error Get Data');
                            this.dispatch('error', e);
                        });
                    }
                },
                responseAdapter: (resp) => {  
                    return {
                        data: resp.data,
                        count: resp.headers['x-total-count'] ? resp.headers['x-total-count'] : resp.headers['Content-Length'] ? resp.headers['Content-Length'] : resp.data.length
                    }
                },
                rowClassCallback(row) {
                    return  (row.isNewData) ? 'highlight':'';
                },
                filterByColumn: true,
                sortable:[],
                templates: {
                    createdDate(h, row) {
                        return moment(row.createdDate).add(7, "hours").format("DD-MMM-YYYY");
                    },
                    authorities(h, row) {
                        let text = '<span class="p-2 mt-1 badge badge-info">'+row.authorities+'</span> <br/>'
                        return row.authorities
                    },
                    activated(h, row) {
                        return row.activated == true ? 'True' : 'False'
                    },
                },
                stickyHeader: true
            },
            tableData: [],
        }
    },
    methods: {
        onLoaded: function(t) {
            t == 0 ? this.isLoading = false : this.isLoading = true  
        }
    }

}
</script>