<template>
    <div>
        <template v-if="!loading">
        <div class="row">
            <div class="col-12">
                <!-- check to pre select only 1 doctor -->
                <doctor-select
                    v-show="doctorSelect"
                    v-model="selectedDokters"
                    :internal-search="false"
                    :grouping="true"
                    :multiple="true"
                    :group-select="true"
                    :placeholder="'Tambah Dokter'"
                    :options="listOptionsDoktor"
                    :search-change="searchChangeGroup"
                    :remove="remove"
                >
                    <template v-slot:selectionTemplateSlot="{data}">
                        <span class="multiselect__single"  v-if="data.values.length &amp;&amp; !data.isOpen">
                            Tambah Dokter
                        </span>
                    </template>
                </doctor-select>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-4 mt-3"  v-for="(value, index) in selectedDokters" :key="value.id">
                <div class="btn-doctor-caller w-100 h-100"
                    :class="(choosenDoctor != null && choosenDoctor.id == value.id) ? 'active': ''">
                    <div class="btn-doctor-caller-delete">
                        <i class="fas fa-trash-alt mr-2" @click="removeItems(value, index)"></i>
                    </div>
                    <label class="w-100">
                        <input type="radio" class="d-none" :value="value" v-model="choosenDoctor">
                        <div class="btn-doctor-caller-content">
                            <div class="btn-doctor-caller-text">
                                {{value.nama}}
                            </div>
                        </div>
                    </label>
                </div>
            </div>
        </div>
        <div class="row border-top mt-3">
            <div class="col-12">
                <h4 class="mt-3">Jam Praktek</h4>
                <div class="d-flex flex-wrap" v-if="jadwals.length > 0">
                    <div class="mr-2" v-for="value in jadwals" :key="value.id">
                        <label class="btn btn-elevate btn-pill btn-sm mb-1"
                            :class="{
                                'active' : (selectedJadwal != null && value.id == selectedJadwal.id),
                                'btn-outline-primary' : (!haveCancel(value.statusJadwalDokters)),
                                'btn-outline-danger' : (haveCancel(value.statusJadwalDokters))
                            }"
                            >
                            <input type="radio" class="d-none" :value="value" v-model="selectedJadwal" >
                            {{value.jamMulai}} - {{value.jamSelesai}}
                        </label>
                        <div class="text-center text-danger kt-font-sm">
                            {{ haveCancel(value.statusJadwalDokters) ? 'Jadwal dicancel' : '' }}
                        </div>
                    </div>
                </div>
                <h6 v-else-if="!choosenDoctor">Tidak ada jam praktek </h6>
                <h6 v-else>Tidak ada jam praktek {{choosenDoctor.nama}} pada {{date}}</h6>
            </div>
        </div>
        </template>
        <div class="row" v-else>
            <div class="col-lg-12 text-center p-5 my-auto">
                <div class="spinner-grow text-info" role="status" style="width: 3rem; height: 3rem;">
                    <span class="sr-only">Loading...</span>
                </div>
                <h6 class="mt-3">Memuat Dokter...</h6>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapMutations, mapActions , mapGetters } from 'vuex';
    import DoctorSelect from'./../../../_select/Doktor.vue';
    export default {
        data(){
            return{
                doctorSelect: true, // boolean untuk pilih jadwal dokter
            }
        },
        components: {
            DoctorSelect,
        },
        computed: {
            ...mapState('CallerStore', {
                queueDevice: state => state.queueDevice,
                userNotification: state => state.userNotification,
            }),
            ...mapState('DoctorCallerStore', {
                listDataDokterGroup: state => state.listDataDokterGroup,
                listOptionsDoktor: state => state.listOptionDokter,
                listBookmarkDokter: state => state.listBookmarkDokter,
                dokter: state => state.dokter,
                date: state => state.selectedDate,
                jadwal: state => state.jadwal,
                loading: state => state.loadingDokter
            }),
            ...mapGetters({
                jadwals: 'DoctorCallerStore/GET_LIST_JADWAL_DOKTER',
            }),
            selectedDokters:{
                get(){
                    return this.listBookmarkDokter;
                },
                set(value){
                    (!this.choosenDoctor) ? this.choosenDoctor = value[0] : this.choosenDoctor = value[value.length-1] 
                    this.setListBookmarkDokter(value);
                }
            },
            choosenDoctor: {
                get(){
                    return this.dokter;
                },
                set(value){
                    this.setDokter(value);
                    this.setJadwal(null);
                    this.setEvents([]);
                    if(this.choosenDoctor){
                            this.selectedJadwal = this.jadwals[0]
                    }
                    this.settingQueueDevice();
                }
            },
            selectedJadwal: {
                get(){
                    return this.jadwal;
                },
                set(value){
                    this.setJadwal(value);
                    this.getDataQueue();
                }
            },
        },
        methods: {
            ...mapMutations({
                setListOptionDokter: 'DoctorCallerStore/SET_LIST_OPTION_DOKTER',
                setListBookmarkDokter: 'DoctorCallerStore/SET_LIST_BOOKMARK_DOKTER',
                setDokter: 'DoctorCallerStore/SET_DOKTER',
                setJadwal: 'DoctorCallerStore/SET_JADWAL',
                setEvents: 'DoctorCallerStore/SET_EVENTS'
            }),
            ...mapActions({
                getDataQueue: 'DoctorCallerStore/GET_DATA_QUEUE',
                saveQueueDevice: 'CallerStore/SAVE_QUEUE_DEVICE',
                setHistoryDoctor: 'CallerStore/SET_HISTORY_DOKTOR_CALLER'
            }),
            searchChangeGroup(query){
                const data = this.listDataDokterGroup.filter(x => {
                    if(x.doctors.find(y => y.nama.toLowerCase().indexOf(query.toLowerCase()) > -1 )){
                        return x
                    } else if(x.nama) {
                        if ( ( x.nama.toLowerCase().indexOf(query.toLowerCase()) > -1 )) {
                            return x;
                        }
                    }
                })
                this.setListOptionDokter(data);
            },
            haveCancel(statusJadwalDokters){
                var found = statusJadwalDokters.find(y => y.tanggal == this.date);
                return (found) ? true : false;
            },
            removeItems(data, index){
                const tempdata = [...this.listBookmarkDokter];
                tempdata.splice(index,1);
                this.setListBookmarkDokter(tempdata);
                this.remove(data);
            },
            remove(data){
                const found = (data.id == this.dokter.id);
                if(found){
                    this.setDokter(null);
                    this.setJadwal(null);
                    this.setEvents([]);
                }
                this.settingQueueDevice();
            },
            settingQueueDevice(){
                setTimeout(() => {
                    const device = {...this.queueDevice};
                    device.queueUnits = [];
                    device.dokters = (this.dokter != null) ? [{
                        id: `${this.dokter.id}`
                    }] : [];
                    // device.notificationId = this.userNotification.notificationId;
                    this.saveQueueDevice(device);
                }, 10);
            }
        },
        watch: {
            loading(val) {
                if(this.choosenDoctor){
                    this.selectedJadwal = this.jadwals[0]
                }
            }
        },
        mounted () {
            // checkpermission for one doctor
            // pre select and hide the multiselect
            // this.$watch(() => this.$refs.selectDokter.responseData, (value) => { this.listOptionDoctor = value;})
        },
        destroyed(){
            this.settingQueueDevice()
        }
    }
</script>

<style lang="scss" scoped>

</style>
