<template>
  <div>
    <base-header base-title="OutPatient Doctor"></base-header>
    <fullscreen ref="fullscreen" @change="fullscreenChange">
    <div class="kt-content kt-grid__item kt-grid__item--fluid"  id="kt_content">
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <form @submit.prevent="search">
              <div class="col-md-12 row">
                  <div class="form-group">
                      <label class="col-12 col-form-label">ID Ruangan :</label>
                      <div class="col-12">
                          <input type="text" class="form-control" placeholder="ID Ruangan" v-model="idSearch">
                      </div>
                  </div>
                  <div class="form-group">
                      <label class="col-12 col-form-label"> &nbsp; </label>
                      <div class="col-12">
                          <button type="submit" class="btn btn-default btn-bold btn-upper"  :disabled="loading">
                              <template v-if="loading">
                                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                  Loading...
                              </template>
                              <template v-else>
                                  <i class="flaticon2-search"></i> {{ $t('button.search') }}
                              </template>
                          </button>
                      </div>
                  </div>
              </div>
            </form>
            <div v-if="roomData.id" class="ml-3">
             <h3>Detail Ruangan</h3>
            <div class="fomr-group">
            <div class="form-group row">
              <label
                for="example-text-input"
                class="col-2 col-form-label"
              >Nama:</label>
              <div class="col-6 my-auto">
                <span>{{ roomData.name }}</span>
              </div>
            </div>
            <div class="form-group row">
              <label
                for="example-text-input"
                class="col-2 col-form-label"
              >Deskripsi:</label>
              <div class="col-6 my-auto">
                <span>{{ roomData.description }}</span>
              </div>
            </div>
            <div class="form-group row">
              <label
                for="example-text-input"
                class="col-2 col-form-label"
              >Section:</label>
              <div class="col-6 my-auto">
                <span>{{ roomData.section }}</span>
              </div>
            </div>
             <div class="form-group row">
              <label
                for="example-text-input"
                class="col-2 col-form-label"
              >Floor:</label>
              <div class="col-6 my-auto">
                <span>{{ roomData.floor }}</span>
              </div>
            </div>
            </div>
            <h3>Dokter</h3>
            
            <div class="form-group row">
            <label for="inputPilihDoctor" class="col-2 col-form-label">{{this.$i18n.t("formInternal.select")}}</label>
            <div class="col-5 my-auto">
                <vue-multi-select
                :options="doctorsName"
                v-model="selectedDoctor"
                :name="'doktor'"
                :validationName="'Doktor'"
                >
                </vue-multi-select>
                
            </div>
            </div>
            <!-- <div class="form-group row">
              <label
                for="example-text-input"
                class="col-2 col-form-label"
              >Nama:</label>
              <div class="col-6 my-auto">
                <span>{{ roomData.dokter.nama }}</span>
              </div>
            </div> -->
            <div class="form-group row" v-if="doctorsData.tujuanRujukan">
              <label
                for="example-text-input"
                class="col-2 col-form-label"
              >Poli:</label>
              <div class="col-6 my-auto">
                <span>{{ doctorsData.tujuanRujukan.nama }}</span>
              </div>
            </div>
                <div class="row ml-1">
                <!-- <button type="submit" class="btn btn-primary">Cancel</button> -->
                <button type="submit" class="btn btn-primary" @click.prevent="submit()">Save</button>
                </div>
            </div>
        </div>
      </div>
    </div>
    </fullscreen>
  </div>
</template>

<script>
import BaseTitle from "./../_base/BaseTitle.vue";
import BaseHeader from "./../_base/BaseHeader.vue";
import Fullscreen from "vue-fullscreen/src/component.vue";
import vueMultiSelect from './../_select/VueMultiSelect'
import { RepositoryFactory } from "./../../repositories/RepositoryFactory";
import SweetAlertTemplate from "./../_general/SweetAlert";
import BlockUI from "../_js/BlockUI";
const blockUI = new BlockUI();
const SweetAlert = Vue.extend(SweetAlertTemplate);
const PatientRepository = RepositoryFactory.get("roomDisplay");
export default {
    data() {
        return {
            idSearch: '',
            loading: false,
            roomData: {},
            listDoctorName: [],
            selectedDoctor: null,
        }
    },
    components: {
        BaseTitle,
        BaseHeader,
        Fullscreen,
        vueMultiSelect
    },
    computed:{
        doctorsName: function () {
        let names = []
        this.listDoctorName.forEach(el => names.push(el.nama));
        return names
        },
        doctorsData(){
            if(this.selectedDoctor) {
              let result = this.listDoctorName.filter(word => word.nama == this.selectedDoctor);
              return result[0]
            } 
            return {}
        }
    },
    methods:{
        submit(){
            let dokter = this.doctorsData.id ? {
                    "id": this.doctorsData.id
                } : null
            let payload = {
                "description": this.roomData.description,
                 dokter,
                "floor": this.roomData.floor,
                "id": this.roomData.id,
                "name": this.roomData.name,
                "section": this.roomData.section
            }
            PatientRepository.updateRoom(payload).then(({data}) => {
                let instance = new SweetAlert().showInfo({
                    swalTitle: "Berhasil!",
                    swalText: "dokter telah dirubah.",
                    swalType: "success",
                    onConfirmButton: function() {
                        blockUI.unblockModal();
                    }
                });
            }).catch(err => {
                let instance = new SweetAlert().showInfo({
                swalTitle: "Gagal!",
                swalText: "slot baru tidak dapat dibuat.",
                swalType: "error",
                onConfirmButton: function() {
                    blockUI.unblockModal();
                }
            });
            })
        },
        fullscreenChange (isFullScreen) {
            this.fullscreen = isFullScreen
        },
        search(){
            this.loading = true
            PatientRepository.getRoom(this.idSearch).then(({data}) => {
                this.roomData = data
                this.loading = false
                this.selectedDoctor = data.dokter ? data.dokter.nama : ''
            }).catch(err => {
                toastr.error('Not Found');
                this.loading = false;
            })
        },
        async getListDokters(){
              try{
                const DoctorRepository = RepositoryFactory.get("doctor");
                const listDokters = new Promise((resolve, reject) => {
                  DoctorRepository.get({}, resolve, reject);
                  });
                 const responseDokter = await Promise.all([listDokters]);
                this.listDoctorName = responseDokter[0].data
              }catch(err){
                toastr.error('error get data');
              }
        },
    },
    mounted(){
        this.getListDokters()
    }
}
</script>

<style>

</style>