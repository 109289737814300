<template>
  <div>
        <base-header base-title="Room Management"></base-header>
        <!-- begin:: Content -->
        <div
        class="kt-content kt-grid__item kt-grid__item--fluid"
        id="kt_content"
        >
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                <base-title :head-title="headTitle"></base-title>
                <div class="kt-portlet__head-toolbar">
                    <div class="kt-portlet__head-wrapper">
                    <auto-refresh
                        ref="auto"
                        :table-name="tableData.tableName"
                        :on-refresh="fetch"
                        :auto-refresh-state="false"
                    ></auto-refresh>
                    <template v-if="checkPermission('PatientResource.POST.Patient')">
                        &nbsp;&nbsp;
                        <base-button-modal
                        :button-text="modalText"
                        :button-icon="modalIcon"
                        :button-name="modalName"
                        :onButtonModalClicked="showModal"
                        ></base-button-modal>
                    </template>
                    </div>
                </div>
                </div>
                <div
                    class="kt-portlet__body tab-content"
                    v-if="isLoaded"
                    id="patient_tab"
                >
                    <div class="tab-pane fade active show" id="room" role="tabpanel">
                        <room-server-table ref="rooms" :room-vue="roomVue"/>
                    </div>
                </div>
                <div
                    v-else
                    class="m-auto p-5 d-flex flex-row h-100"
                >
                <div class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"></div>
                <h5 class="my-auto pl-5">Loading ...</h5>
                </div>
                <form-modal
                    v-if="isShowModal"
                    :show-modal.sync="isShowModal"
                    :modal-data="dataEdit"
                    @event="fetch"
                ></form-modal>
            </div>
        </div>
        <!-- end:: Content -->
  </div>
</template>

<script>
import BaseHeader from "./../_base/BaseHeader";
import AutoRefresh from "../_general/AutoRefresh";
import BaseButtonModal from "./../_base/BaseButtonModal";
import BaseTitle from "./../_base/BaseTitle";
import CheckPermission from "./../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import RoomServerTable from "./_components/RoomServerTable.vue";
import LoadSpinner from "./../_general/LoadSpinner";



export default {
    data() {
        var vx = this;
        return {
            roomVue: vx,
            headTitle: 'Daftar Ruangan',
            modalText: 'Tambah Ruangan',
            modalIcon: 'flaticon2-add-1',
            modalName: 'modal_ruangan',
            isLoaded: true,
            isShowModal: false,
            tableData: [],
            dataEdit: null
        }
    },
    components: {
        BaseTitle,
        BaseHeader,
        AutoRefresh,
        BaseButtonModal,
        RoomServerTable,
        FormModal: () => ({
            component: new Promise(function (resolve, reject) {
                setTimeout(function () {
                resolve(
                    import(
                    /* webpackChunkName: "modal-patient" */ "./_components/FormModal.vue"
                    )
                );
                }, 1000);
            }),
            loading: LoadSpinner
        }),
    },
    watch: {
        isShowModal: function (val) {
        if (!val) {
            this.dataEdit = null;
        }
        }
    },
    methods: {
        fetch: function(resolve) {
            this.$refs.rooms.$refs.table.getData();
            if(resolve != undefined){
                resolve();
            }
        },
        checkPermission: function (method) {
           return checkPermission.isCanAccess(method);
        },
        showModal() {
            this.isShowModal = true
        }
    }
}
</script>

<style>

</style>