<template>
    <div class="printBilling">
        <div>
            <div class="row mb-3">
                <div class="col-12">
                    <h1 class="font-weight-bold">Sahabat Mandaya</h1>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-3 row font-weight-bold">
                    <div class="col-8">No.RM</div>
                    <div class="col text-right">:</div>
                </div>
                <div class="col-3">
                    {{ transaksiReferral.medicalRecordNo }}
                </div>
                <div class="col-3 row font-weight-bold">
                    <div class="col-8">Diagnosa</div>
                    <div class="col text-right">:</div>
                </div>
                <div class="col-3">
                    {{ transaksiReferral.diagnosa }}
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-3 row font-weight-bold">
                    <div class="col-8">Nomor Registrasi</div>
                    <div class="col text-right">:</div>
                </div>
                <div class="col-3">
                    {{ transaksiReferral.nomorRegistrasi }}
                </div>
                <div class="col-3 row font-weight-bold">
                    <div class="col-8">Perujuk</div>
                    <div class="col text-right">:</div>
                </div>
                <div class="col-3">
                    {{ fullName }}
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-3 row font-weight-bold">
                    <div class="col-8">Tanggal Masuk</div>
                    <div class="col text-right">:</div>
                </div>
                <div class="col-3">
                    {{ tglMasuk }}
                </div>
                <div class="col-3 row font-weight-bold">
                    <div class="col-8">Tujuan Rujukan</div>
                    <div class="col text-right">:</div>
                </div>
                <div class="col-3">
                    {{ transaksiReferral.tujuanRujukan.nama }}
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-3 row font-weight-bold">
                    <div class="col-8">Nama Pasien</div>
                    <div class="col text-right">:</div>
                </div>
                <div class="col-3">
                    {{ transaksiReferral.nama }}
                </div>
                <div class="col-3 row font-weight-bold">
                    <div class="col-8">Penjamin</div>
                    <div class="col text-right">:</div>
                </div>
                <div class="col-3">
                    {{ transaksiReferral.jenisPenjamin.nama }}
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-3 row font-weight-bold">
                    <div class="col-8">Tanggal Lahir</div>
                    <div class="col text-right">:</div>
                </div>
                <div class="col-3">
                    {{ transaksiReferral.tanggalLahir | formatDate }}
                </div>
                <div class="col-3 row font-weight-bold">
                    <div class="col-8">Kelas Perawatan</div>
                    <div class="col text-right">:</div>
                </div>
                <div class="col-3">
                    {{ kelas }}
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-3 row font-weight-bold">
                    <div class="col-8">Dokter Utama</div>
                    <div class="col text-right">:</div>
                </div>
                <div class="col-3"></div>
                <div class="col-3 row font-weight-bold">
                    <div class="col-8">Tanggal Keluar</div>
                    <div class="col text-right">:</div>
                </div>
                <div class="col-3">
                    {{ tglPulang }}
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-3 row font-weight-bold">
                    <div class="col-8">Alamat</div>
                    <div class="col text-right">:</div>
                </div>
                <div class="col-3">
                    {{ transaksiReferral.alamat }}
                </div>
            </div>
        </div>
        <br/><hr/>
        <div>
            <div class="row mb-3">
                <div class="col-12">
                    <h1 class="font-weight-bold">Transaksi Perawatan</h1>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-6 row font-weight-bold">
                    <div class="col-1">1. </div>
                    <div class="col-7">Total Biaya Perawatan</div>
                    <div class="col text-right">:</div>
                </div>
                <div class="col-2 text-right">
                    Rp. {{ transaksiReferral.totalBiaya | seperator }}
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-6 row font-weight-bold">
                        <div class="col-1">2. </div>
                    <div class="col-7">Total Biaya Kesehatan</div>
                    <div class="col text-right">:</div>
                </div>
                <div class="col-2 text-right">
                    Rp. {{ transaksiReferral.fee | seperator }}
                </div>
            </div>
            <br/><br/>
            <div class="row mb-2 font-weight-bold">
                <div class="col-6">
                    Catatan :
                </div>
                <div class="col-6 text-center">
                    Karawang, {{ currentDate }}
                </div>
            </div>
            <br><br><br/>
            <br><br><br/>
            <div class="row mb-2 font-weight-bold">
                <div class="col-6 ">
                    *Catatan di isi admin
                </div>
                <div class="col-6 text-center">
                    (.........................................................)
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import JejakEnum from "./../../constants/jejak-enum";
export default {
    data() {
        return {
            tglMasuk: '',
            tglPulang: ''
        }
    },
    props:{
        transaksiReferral: {type: Object}
    },
    filters:{
        seperator : function(value){
            if (value !== null) {
                const parts = value.toString().split('.');
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                return parts.join(',');
            }
            return value;
        },
        formatDate: function(value){
            return moment(value).format('DD MMMM YYYY');
        }
    },
    methods:{
        findTimeStamp: function(array, searchKey, searchValue) {
            const sortArray = array.sort((a,b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime() );
            const index = sortArray.findIndex(x => x[searchKey] === searchValue);
            if (index >= 0) {
                return sortArray[index];
            } else {
                return null;
            }
        },
        getTanggal: function(){
            const datang = this.findTimeStamp(this.transaksiReferral.jejaks, 'enumStage', JejakEnum.PASIEN_DATANG);
            const pulang = this.findTimeStamp(this.transaksiReferral.jejaks, 'enumStage', JejakEnum.PASIEN_PULANG);
            this.tglMasuk = (datang) ? moment(datang.timestamp).format('DD MMMM YYYY') : '';
            this.tglPulang = (pulang)? moment(pulang.timestamp).format('DD MMMM YYYY') : '';
        }
    },
    computed:{
        fullName: function(){
            const fname = (this.transaksiReferral.perujuk.firstName) ? this.transaksiReferral.perujuk.firstName : '';
            const lname = (this.transaksiReferral.perujuk.lastName) ? this.transaksiReferral.perujuk.lastName : '';
            return fname+' '+lname;
        },
        tindakan: function(){
            if(this.transaksiReferral.tindakan){
                return this.transaksiReferral.tindakan.parent.nama + ' - '+ this.transaksiReferral.tindakan.nama;
            } else {
                return '';
            }
        },
        kelas: function(){
            return (this.transaksiReferral.kelas) ? this.transaksiReferral.kelas.nama : '';
        },
        currentDate: function(){
            return moment().format('DD MMMM YYYY');
        }
    },
    mounted () {
        this.getTanggal()
    },
}
</script>

<style>

</style>
