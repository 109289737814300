<template>
  <v-breakpoint ref="breakpoint">
    <div class="timeline-doctorcaller">
      <div class="timeline-loading" v-if="loadingTimeline">
        <div class="row">
          <div
            class="spinner-grow text-info mx-auto p-5"
            role="status"
            style="width: 3rem; height: 3rem"
          >
            <span class="sr-only"></span>
          </div>
        </div>
      </div>
      <h6 v-if="jadwal == null" class="text-danger">
        Anda belum memilih jam praktek
      </h6>
      <template>
        <FullCalendar
          ref="timeGridCalendar"
          defaultView="resourceTimeGridDay"
          :plugins="timeGridPlugins"
          :schedulerLicenseKey="'GPL-My-Project-Is-Open-Source'"
          :header="false"
          :allDaySlot="false"
          :slotDuration="'00:05'"
          :height="height"
          :resources="resources"
          :events="events"
          :minTime="'07:00:00'"
          :eventTimeFormat="eventTimeFormat"
          :slotLabelFormat="slotLabelFormat"
          :editable="editable"
          :eventRender="eventRender"
          :displayEventTime="false"
          :nowIndicator="nowIndicator"
          :now="now"
          @eventDrop="eventDrop"
        ></FullCalendar>
      </template>
      <div class="row" v-if="loading">
        <div class="col-lg-12 text-center p-5 my-auto">
          <div
            class="spinner-grow text-info"
            role="status"
            style="width: 3rem; height: 3rem"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <h6 class="mt-3">Memuat Antrian...</h6>
        </div>
      </div>
    </div>
  </v-breakpoint>
</template>

<script>
import EventCalendar from "./../_components/EventCalendar.vue";
import FullCalendar from "@fullcalendar/vue";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { VBreakpoint } from "vue-breakpoint-component";
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import { RepositoryFactory } from "./../../../../repositories/RepositoryFactory";
const queueResource = RepositoryFactory.get("queueResource");
import BlockUI from "../../../_js/BlockUI";
const blockUI = new BlockUI();
import SweetAlertTemplate from "../../../_general/SweetAlert";
const SweetAlert = Vue.extend(SweetAlertTemplate);
export default {
  components: {
    VBreakpoint,
    FullCalendar
  },
  data() {
    var vx = this;
    return {
      loadingTimeline: false,
      scope: null,
      timeGridPlugins: [resourceTimeGridPlugin, interactionPlugin],
      editable: true,
      calenderApi: null,
      eventTimeFormat: {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      },
      slotLabelFormat: {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      },
      eventRender: function (info) {
        const value = JSON.parse(info.event.title);
        let eventTimeGrid = Vue.extend(EventCalendar);
        let instance = new eventTimeGrid({
          propsData: {
            xVue: vx.$store,
            value: value,
            start: info.event.start,
            end: info.event.end,
          },
        });
        instance.$mount(info.el.querySelector(".fc-content"));
      },
      eventDrop: function (info) {
        let title = JSON.parse(info.event.title);
        let value = {
          estJamMulai: moment(info.event.start).format("HH:mm:ss"),
          estJamSelesai: moment(info.event.end).format("HH:mm:ss"),
          id: title.id,
        };
        const jadwal_awal = title.estJamMulai;
        const content = document.createElement("div");
        content.innerHTML = `Merubah Jadwal dari <b>${jadwal_awal}</b> menjadi <b>${value.estJamMulai}</b>`;
        new SweetAlert().showConfirm({
          swalTitle: "Apakah Anda yakin",
          swalText: content,
          swalType: "info",
          onConfirmButton: function () {
            blockUI.blockModal();
            vx.postTimeline(value);
          },
          onCancelButton: function () {
            info.revert();
            blockUI.unblockModal();
          },
        });
        // if (confirm("Are you sure?") == true) {
        //     vx.postTimeline(value)
        // } else {
        //     info.revert()
        //     console.log("You pressed Cancel!");
        // }
      },
    };
  },
  watch: {
    date(newValue) {
      this.calenderApi.gotoDate(newValue);
    },
    moveTo(newValue) {
      setTimeout(() => {
        this.calenderApi.scrollToTime(newValue);
      }, 500);
    },
  },
  computed: {
    ...mapState("DoctorCallerStore", {
      resources: (state) => state.resources,
      events: (state) => state.events,
      date: (state) => state.selectedDate,
      jadwal: (state) => state.jadwal,
      moveTo: (state) => state.moveTo,
    }),
    loading: {
      get() {
        return this.$store.state.DoctorCallerStore.loadingTimeLine;
      },
      set(val) {
        return this.$store.commit(
          "DoctorCallerStore/SET_LOADING_TIMELINE",
          val
        );
      },
    },
    height() {
      if (this.scope) {
        if (this.scope.isLarge || this.scope.isXlarge) {
          return (
            this.scope.innerHeight -
            parseInt($("#headerDoctorCaller").height()) -
            110
          );
        } else {
          return (
            this.scope.innerHeight -
            parseInt($("#headerDoctorCaller").height()) -
            parseInt($("#ControlDoctorCaller").height()) -
            110
          );
        }
      }
      return 700;
    },
    nowIndicator() {
      return !!this.moveTo;
    },
    now() {
      return moment().format("YYYY-MM-DDTHH:mm:ss");
    },
  },
  methods: {
    ...mapMutations({
      setSelectedDate: "DoctorCallerStore/SET_SELECTED_DATE",
    }),
    ...mapActions({
      getDataDoctor: "DoctorCallerStore/GET_DATA_DOCTOR",
    }),
    async postTimeline(value) {
      this.loadingTimeline = true;
      let payload = [];
      this.events.forEach((el) => {
        let title = JSON.parse(el.title);

        let load = {
          estJamMulai: moment(el.start).format("HH:mm:ss"),
          estJamSelesai: moment(el.end).format("HH:mm:ss"),
          id: title.id,
        };

        if (title.id == value.id) {
          payload.push(value);
        } else {
          payload.push(load);
        }
      });
      try {
        const { data } = await queueResource.postWalkinTimeline(payload);
      } catch (err) {
        toastr.error("error get data timeline");
      } finally {
        setTimeout(() => {
          this.loadingTimeline = false;
        }, 500);
      }
    },
  },
  mounted() {
    this.$watch(
      () => this.$refs.breakpoint.scope,
      (value) => {
        this.scope = value;
      }
    );
    this.calenderApi = this.$refs.timeGridCalendar.getApi();
    this.setSelectedDate(this.date);
    this.calenderApi.gotoDate(this.date);
    this.getDataDoctor();
  },
};
</script>
