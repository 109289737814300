<template>
  <div class="text-center">
    <template v-if="checkPermission(permissionUpdate)">
      <button
        @click="editData()"
        class="btn btn-sm btn-clean btn-icon btn-icon-md"
        title="Edit"
      >
        <i class="la la-pencil-square"></i>
      </button>
    </template>
    <template v-if="checkPermission(permissionDelete)">
      <button
        @click="detele()"
        class="btn btn-sm btn-clean btn-icon btn-icon-md"
        title="Hapus"
      >
        <i class="la la-trash"></i>
      </button>
    </template>
  </div>
</template>

<script>
import CheckPermission from "../../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
const RoomDisplayRepository = RepositoryFactory.get("roomDisplay");
import SweetAlertTemplate from "./../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
const SweetAlert = Vue.extend(SweetAlertTemplate);

export default {
  data() {
    return {
      permissionUpdate: 'RoomDisplayResource.PUT.RoomDisplays',
      permissionDelete: 'RoomDisplayResource.DELETE.RoomDisplays.id'
    }
  },
  props: {
    rowData: { type: Object },
    storeObject: {},
    roomVue: { type: Object },
  },
  computed: {
    dataEdit: function () {
      return this.storeObject.state.MasterStore.dataEdit;
    }
  },
  methods: {
    detele (){
        let vx = this
        let instance = new SweetAlert().showConfirm({
            swalTitle: "Apakah Anda yakin?",
            swalText: "Data Ruangan akan dihapus.",
            swalType: "info",
            onConfirmButton: function() {
                vx.deleteData()
            },
            onCancelButton: function() {
                blockUI.unblockModal();
            }
        });
    },
    async deleteData() {
      var vx = this;

      await RoomDisplayRepository.deleteRoom(this.rowData.id)
        .then(response => {
          new Promise((resolve, reject) => {
            vx.roomVue.fetch(resolve);
          }).then((response) => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Jadwal Dokter berhasil terhapus.",
              swalType: "success",
              onConfirmButton: function () {
                blockUI.unblockPage();
              }
            });
          });
        })
        .catch(error => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Jadwal Dokter gagal terhapus.",
            swalType: "error",
            onConfirmButton: function () {
              blockUI.unblockPage();
            }
          });
        });
    },
    async editData() {
        const { data } = await RoomDisplayRepository.getRoom(this.rowData.id);
        blockUI.unblockPage();
        this.roomVue.dataEdit = data;
        this.roomVue.showModal();
    },
    checkPermission: function (method) {
      return checkPermission.isCanAccess(method);
    }
  },
  mounted() {
  }
};
</script>
