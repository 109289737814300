<template>
    <v-breakpoint>
    <div slot-scope="scope">
        <div class="row" id="headerDoctorCaller">
            <div class="col-md-9">
                <div class="row">
                    <button class="col-auto btn btn-default btn-elevate btn-circle btn-icon mt-2" @click="showCaller(callerType.OPTION_CALLER)">
                        <i class="fas fa-arrow-left"></i>
                    </button>
                    <div class="col-md-auto col-auto mt-2" v-if="scope.isLarge || scope.isXlarge">
                        <button class="btn btn-default btn-bold btn-font-md w-100" type="button" @click="today()">
                            Hari ini
                        </button>
                    </div>
                    <div class="col-md-auto col-auto mt-2">
                        <div class="btn-group" role="group">
							<button class="btn btn-clean btn-bold  btn-font-md" type="button" @click="prev()">
                                    <i class="fas fa-chevron-left"></i>
                            </button>
                            <div class="btn-group" role="group">
                                <button class="btn btn-clean btn-bold btn-font-md" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {{selectedDate | filterDate}}
                                </button>
                                <div class="dropdown-menu p-3">
                                    <vue-date-picker
                                        v-model="dates"
                                        :disabled-date="'notBeforeToday'"
                                        :inline="true"
                                        :change="changeDate"
                                    >
                                    </vue-date-picker>
                                </div>
                            </div>
                            <button class="btn btn-clean btn-bold btn-font-md" type="button" @click="next()">
                                <i class="fas fa-chevron-right"></i>
                            </button>
						</div>
                    </div>
                    <div class="col-md-auto col-auto mt-2" v-if="scope.isSmall || scope.noMatch">
                        <button type="button" class="btn btn-default btn-elevate btn-circle btn-icon mr-2" @click="showHelp()" >
                            <i class="fas fa-question"></i>
                        </button>
                        <button class="btn btn btn-default btn-elevate btn-circle btn-icon mr-2" @click="configPrinter">
                            <i class="fas fa-print"></i>
                            <i class="fas fa-cog" style="margin-left: 2px"></i>
                        </button>
                        <button class="btn btn-default btn-elevate btn-circle btn-icon" @click="toggle" >
                                <i class="fas fa-expand"></i>
                        </button>
                    </div>
                    <div class="col-md-auto col-auto my-auto">
                        <div class="d-flex mt-2">
                            <!-- <div class="kt-font-bold kt-font-lg my-auto">
                                <span>{{(dokter) ? dokter.nama : 'Anda Belum Memilih Dokter' }}</span> 
                            <br>
                                <span v-if="jadwal"> {{jadwal.jamMulai}} - {{jadwal.jamSelesai}}</span>
                            </div> -->
                             
                            <!-- ini update caller name -->
                            <span class="kt-font-bold kt-font-lg my-auto">Nama Ruangan:&nbsp;</span>
                            <div v-if="editCaller">
                                <div class="input-group">
                                    <input type="text" class="form-control" v-model="callerName" placeholder="Nama Caller">
                                    <div class="input-group-append">
                                        <button class="btn btn-default btn-bold" type="button"  @click="updateFriendlyName()">
                                            <i class="fa fa-save"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="kt-font-bold kt-font-lg" v-else>
                                <span>{{ callerName }} </span>
                                <button type="button"
                                    class="btn btn-outline-hover-primary btn-sm btn-icon btn-circle"
                                    @click="editCaller = true"
                                >
                                    <i class="fa fa-edit kt-font-primary"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3" v-if="scope.isLarge || scope.isXlarge || scope.isMedium">
                <div class="row flex-row-reverse">
                    <div class="col-md-auto mt-2">
                        <button type="button" class="btn btn-default btn-elevate btn-circle btn-icon mr-2" @click="showHelp()" >
                            <i class="fas fa-question"></i>
                        </button>
                        <button class="btn btn btn-default btn-elevate btn-circle btn-icon mr-2" @click="configPrinter">
                            <i class="fas fa-print"></i>
                            <i class="fas fa-cog" style="margin-left: 2px"></i>
                        </button>
                        <button class="btn btn-default btn-elevate btn-circle btn-icon" @click="toggle" >
                                <i class="fas fa-expand"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </v-breakpoint>
</template>

<script>
    import { mapState, mapMutations, mapActions } from 'vuex';
    import CallerType from "./../../../../constants/caller-type-enum";
    import VueDatePicker from '../../../_general/VueDatePicker.vue';
    import { VBreakpoint } from 'vue-breakpoint-component';
    import DoctorControl from './DoctorControl.vue';
    import qz from 'qz-tray'

    export default {
        components: {
            VueDatePicker,
            VBreakpoint,
            DoctorControl
        },
        data() {
            return {
                callerType: CallerType,
                editCaller: false
            }
        },
        computed: {
            ...mapState('DoctorCallerStore', {
                selectedDate: state => state.selectedDate,
                dokter: state => state.dokter,
                jadwal: state => state.jadwal,
            }),
            ...mapState('CallerStore', {
                queueDevice: state => state.queueDevice,
            }),
            ...mapState('DoctorCallerStore', {
                doctorCallerName: state => state.doctorCallerName
            }),
            dates: {
                get: function () {
                    return this.selectedDate;
                },
                set: function (newValue) {
                   this.setSelectedDate(newValue);
                }
            },
            callerName: {
                get: function () {
                    return this.doctorCallerName;
                },
                set: function (newValue) {
                   this.setDoctorCallerName(newValue);
                }
            },
        },
        methods: {
            ...mapMutations({
                setViewCaller: 'CallerStore/SET_VIEW_CALLER',
                setSelectedDate: 'DoctorCallerStore/SET_SELECTED_DATE',
                setShowModalHelp:'DoctorCallerStore/SET_SHOW_MODAL_HELP',
                setSettingPrinter: "CallerStore/SET_SETTING_PRINTER",
                setShowModalPrinter: "CallerStore/SET_SHOW_MODAL_PRINTER",
                setDoctorCallerName: 'DoctorCallerStore/SET_DOCTOR_CALLER_NAME',
            }),
            ...mapActions({
                getDataDoctor: 'DoctorCallerStore/GET_DATA_DOCTOR',
                saveQueueDevice: 'CallerStore/SAVE_QUEUE_DEVICE',
            }),
            showCaller(data){
                this.setViewCaller(data)
            },
            toggle() {
                this.$emit('clickToggle', true);
            },
            today(){
                const today = moment().format('YYYY-MM-DD');
                this.setSelectedDate(today);
                this.getDataDoctor();
            },
            next(){
                const nextday = moment(this.selectedDate).add(1, 'days').format('YYYY-MM-DD');
                this.setSelectedDate(nextday);
                this.getDataDoctor();
            },
            prev(){
                const prevDay = moment(this.selectedDate).subtract(1, "days");
                if(prevDay.isSameOrAfter(moment(), 'day')){
                    this.setSelectedDate(prevDay.format('YYYY-MM-DD'));
                    this.getDataDoctor(prevDay.format('YYYY-MM-DD'));
                }
            },
            changeDate(){
                this.getDataDoctor();
            },
            showHelp(){
                this.setShowModalHelp(true)
            },
            configPrinter(){
                this.setSettingPrinter(true)
                this.setShowModalPrinter(true)
            },
            updateFriendlyName(){
                const device = {...this.queueDevice};
                device.friendlyName = this.doctorCallerName
                this.saveQueueDevice(device);
                this.editCaller = false
            },
        },
        filters: {
            filterDate: function(value) {
                return moment(value).format('DD MMM YYYY');
            }
        },

    }
</script>

<style lang="scss" scoped>

</style>
